import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import Navbar from './Navbar';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import moment from 'moment';

const UsersEdit = () => {
  const dataTableRef = useRef();
  const [show, setShow] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const handleShow = (id) => {
    setUserIdToDelete(id);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { apipath } = useAuth();

  useEffect(() => {
    $(dataTableRef.current).DataTable();
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${apipath}/users/details`);
      const filteredUsers = response.data.user.filter(user => user.role === 'user');
      setUsers(filteredUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await axios.delete(`${apipath}/users/details/${userIdToDelete}`);
      await fetchUsers(); // Refresh users after deletion
      setShow(false);
      setIsLoading(false);
    } catch (error) {
      console.error('Error deleting user:', error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className='home'>
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className='bi bi-menu'></i>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this user?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleDelete}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <h5>User Details</h5>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                {/* <table id="datatable" className="table" ref={dataTableRef}> */}
                <table id="datatable" className="table" >
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>User Profile</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Joined On</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => (
                      <tr key={user._id}>
                        <td className='text-center'>{index + 1}</td>
                        <td className='userImage'>
                          <span><img src="imgs/user.png" alt="User" /></span>
                        </td>
                        <td>{user.firstName}{" "}{user.lastName}</td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                        <td>{moment(user.createdAt).format('YYYY-MM-DD')}</td>
                        <td className={user.status ? "success" : "pending"}>
                          <span><i className={user.status ? "fa-solid fa-check" : "fa-solid fa-spinner"}></i> {user.status ? "Active" : "Inactive"}</span>
                        </td>
                        <td>
                          {/* <Link to={`/user-details/${user._id}`}><i className="fa-regular fa-pen-to-square"></i></Link> */} {" "}
                          <i className="fa-solid fa-trash" onClick={() => handleShow(user._id)} style={{ cursor: 'pointer' }}></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UsersEdit;
