import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import Navbar from './Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

const DriverSafetySecurityDetails = () => {

const [show, setShow] = useState(false);
const [driverSafetySecurityIdToDelete, setDriverSafetySecurityIdToDelete] = useState(null);

const handleShow = (id) => {
    setDriverSafetySecurityIdToDelete(id);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  useEffect(() => {
    $(dataTableRef.current).DataTable();
  }, []);

  const navigate = useNavigate();
    const [driverSafetySecuritys, setDriverSafetySecuritys] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { apipath } = useAuth();

    const dataTableRef = useRef();

    useEffect(() => {
        fetchDriverSafetySecuritys();
    }, []);

    useEffect(() => {
        $(dataTableRef.current).DataTable();
      },[]);

    const fetchDriverSafetySecuritys = async () => {
        try {
            const response = await axios.get(apipath + '/safety-security-driver/details');
            setDriverSafetySecuritys(response.data.safetySecurityDriver);
        } catch (error) {
            console.error('Error fetching safetySecuritys:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            setIsLoading(true);
            await axios
            .delete(apipath + `/safety-security-driver/details/${driverSafetySecurityIdToDelete}`);
            await fetchDriverSafetySecuritys(); // Refresh courses after deletion
            setShow(false);
            setIsLoading(false);
        } catch (error) {
            console.error('Error deleting safetySecuritys:', error);
            setIsLoading(false);
        }
    };

  return (
    <>
    <SideBar />
    <Navbar />

    <section className='home'>
    <div className="toggle-sidebar" style={{display: "none"}}>
            <i className='bi bi-menu'></i>
        </div>
        
        <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete driver safety & security</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-content1">
                            <p>Are you sure you want to delete this driver safety & security?</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="modal-footer-btn" onClick={handleDelete} disabled={isLoading}>
                            {isLoading ? "Deleting..." : "Delete"}
                        </button>
                        <button className="modal-footer-btn" onClick={handleClose}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>


        <div className="container">
            <div className="row foot-tablerow">
                <h5>Driver Safety & Security Details</h5>
                <div className="add-button">
                            <Link to="/add-driver-safety-security"><button>+ Add Driver Safety & Security</button></Link>
                        </div>
                <div className="col-lg-11 maintable-column">
                    <div className="container mt-4">
                        <table id="datatable" className="table" ref={dataTableRef}>
                            <thead>
                                <tr> 
                                    <th>SN</th>
                                    <th>Driver Safety & Security Heading</th>
                                    <th>Driver Safety & Security Text</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                            {driverSafetySecuritys.map((driverSafetySecurity, index) => (
                      <tr key={driverSafetySecurity._id}>
                        <td className='text-center'>{index + 1}</td>
                        <td>{driverSafetySecurity.safetySecurityHeading}</td>
                        <td>{driverSafetySecurity.SafetySecurityText}</td>
                        <td>
                        <Link to={`/edit-driver-safety-security/${driverSafetySecurity._id}`}><i className="fa-regular fa-pen-to-square"></i></Link> &nbsp;
                          <i className="fa-solid fa-trash" onClick={() => handleShow(driverSafetySecurity._id)} style={{ cursor: 'pointer' }}></i>
                        </td>
                      </tr>
                    ))}  

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
        </section>
    
    </>
  )
}

export default DriverSafetySecurityDetails;