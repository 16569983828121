import React, { useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";

const UserDetailsEdit = () => {
  const [imageUrl, setImageUrl] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div class="container pt-3">
          <div class="row top-barcolumn">
            <h5>Edit User Information</h5>
            <div class="row nav-column">
              <div>
                <form className="row">
                  <div class="col-lg-6 mb-3">
                    <label for="nav-items1" class="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="nav-item1"
                      aria-describedby="nav-item-edit"
                      placeholder="Name"
                    />
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label for="nav-items2" class="form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="nav-item2"
                      aria-describedby="nav-item-edit"
                      placeholder="Email"
                    />
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label for="nav-items2" class="form-label">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="nav-item2"
                      aria-describedby="nav-item-edit"
                      placeholder="Number"
                    />
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label for="nav-items2" class="form-label">
                      Joined
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="nav-item2"
                      aria-describedby="nav-item-edit"
                      placeholder="Joined"
                    />
                  </div>
                  {/* <div className="col-lg-12 mb-3">
                    <label for="nav-items1" className="form-label">
                      Blog Content
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      aria-describedby="nav-item-edit"
                      placeholder="Content"
                    ></textarea>
                  </div> */}

                  
                  <div className="col-lg-7 ">
                    <div className="logo-insidebox">
                      <div>
                        <h5>Existing Profile Image</h5>
                        <img
                          id="blah"
                          src="http://placehold.it/180"
                          alt="your profile"
                          className="mt-3"
                        />
                      </div>
                      <div className="pt-5">
                        <h5>Change Profile</h5>
                        <input
                          type="file"
                          onchange={handleFileChange}
                          className="pt-3"
                        />
                        {imageUrl && (
                          <img
                            src={imageUrl}
                            alt="Preview"
                            style={{ width: "100px", height: "100px" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                     Status
                    </label>
                    <div class="radio-formbox">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked
                        />
                        <label class="form-check-label" for="flexRadioDefault1">
                          Show
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                        />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Hide
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <button type="submit" className="btn btn-success">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserDetailsEdit;
