import React from "react";

const Privacypolicy = () => {
    return (
        <>
            <div class="container-from-text">
                <header>
                    <h1>Etorides E-Rishkaw Privacy Policy</h1>
                    <h2>1. Introduction</h2>
                </header>
                <section>
                    <ul>
                        <li><h5>Purpose:This Privacy Policy outlines how Etorides E-Rishkaw Service collects, uses,
                            and protects your personal information.
                        </h5></li>
                        <li><h5>Scope: This Policy applies to all users of our services, including riders, drivers, and other individuals who interact with our platform.
                        </h5></li>
                    </ul>
                    <h2>2. Information We Collect</h2>
                    <h5>We collect two main categories of information:</h5>
                    <ul>
                        <li><h5>Personal Information:This includes data that can identify you as an individual, such as:</h5>
                            <li><h6>Name</h6></li>
                            <li><h6>Contact information (email, phone number)</h6></li>
                            <li><h6>Payment information (credit/debit card details)</h6></li>
                            <li><h6>Location data (GPS coordinates)</h6></li>
                            <li><h6>Ride history</h6></li>
                            <li><h6>Government-issued identification (e.g., driver's license, passport)</h6></li>
                        </li>
                    </ul>

                    <ul>
                        <li><h5>Usage Data: This includes information about how you use our services, such as:</h5></li>
                        <li><h6>Frequency of rides</h6></li>
                        <li><h6>Features used</h6></li>

                    </ul>


                    <h2>3. How We Use Your Information</h2>
                    <h5>We use your information for several purposes:</h5>
                    <ul>
                        <li><h5> Providing Services: To manage reservations, provide ride-hailing services, and handle
                            payment processing.</h5><br />
                            <h6>
                                ● Improving Our Services: to evaluate usage trends, spot patterns, and improve our
                                platform.<br />
                                ● Personalized Experiences:to personalize your experience (e.g., recommend pickup
                                locations close to your frequent destinations) based on your usage history and
                                preferences.<br />
                                ● Fraud Prevention: To detect and prevent fraudulent activity.<br />
                                ● Legal Compliance: To comply with applicable laws and regulations (e.g., responding to
                                law enforcement requests).
                            </h6>
                        </li></ul>

                        <h2>4. Sharing Your Information</h2>
                        <h6>● Third-Party Service Providers: Your information might be disclosed to dependable
outside service providers who help us run our services (such cloud providers and
payment processors).<br/><br/>
● Law Enforcement: If mandated by law, or in order to defend our rights or the safety of
others, we might provide your information to law enforcement.
</h6>

<h2>5. Data Security</h2>
<h6>
● Measures: We implement reasonable security measures to protect your personal
information from unauthorized access, disclosure, or misuse.<br/><br/>
● Regular Reviews:We assess and upgrade our security procedures on a regular basis to
counter emerging threats.
</h6>

<h2>6. Your Rights</h2>
<h6>● Access and Rectification:You are entitled to see and update the personal data that you
provide.<br/><br/>
● Erasure: In some situations, you have the right to request that your personal data be
deleted.<br/><br/>
● Object to Processing:There's a chance you have objections to specific data processing
methods.<br/><br/>
● Data Portability: In some situations, you have the right to request that your personal
data be deleted.
</h6>

<h2>7. Cookies and Tracking Technologies</h2>
<h6>● Usage: Cookies and other tracking technologies are used by us to gather data about
how you use our app and website.<br/><br/>
● Management:Through the settings of your browser or application, you may control your
cookie choices.</h6>

<h2>8. Changes to This Policy</h2>
<h6>● Updates: This policy may be changed from time to time. You will be informed of any
important changes</h6>

<h2>9. Contact Us</h2>
<h6>● Questions: If you have any questions about this policy or our privacy practices, please
contact us atSend an email to  <a href="mailto:rideseto@gmail.com">rideseto@gmail.com</a>.
</h6>

<h2>Additional Considerations</h2>
<h6>● Ride Requests: Requests for the transportation of both passengers and goods may
come in for drivers. Depending on their interests and available time, they can accept or
reject inquiries.<br/><br/>
● Working Hours: Our services are available around-the-clock, however in compliance
with applicable labor rules, drivers are usually only allowed to work a maximum of 12
hours per day.<br/><br/>
● Movement Restrictions: Drivers are unable to travel from one location to another
without a ride request that is approved by the Etorides E-Rishkaw platform.<br/><br/>
● Earnings Calculation: The amount of money a driver makes is determined by how
many rides they complete, how far they travel, and how long the journeys last.
</h6>
                </section>
            </div>
        </>
    );
};

export default Privacypolicy;
