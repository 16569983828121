import React from "react";

const SupportTeam = () => {
    return (
        <>
            <div class="container maintenance-message">
                <div class="container marquee-content">
                    Etorides <span class="highlight">E-Rishkaw</span> <br />Support Email us : <br /> rideseto@gmail.com
                </div>
            </div>
        </>
    );
};

export default SupportTeam;
