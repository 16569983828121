import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import Navbar from './Navbar';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import moment from 'moment';
import "datatables.net";
import iziToast from 'izitoast';

const DriveDetails = () => {
  const dataTableRef = useRef(null);
  const [show, setShow] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { apipath } = useAuth();

  useEffect(() => {
    // Initialize DataTable only when users data is available
    if (users.length > 0 && dataTableRef.current) {
      $(dataTableRef.current).DataTable();
    }
  }, [users]);

  useEffect(() => {
    fetchUsers();
  }, []); // Empty dependency array ensures this runs only on mount

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${apipath}/users/details`);
      const filteredUsers = response.data.user.filter(user => user.role === 'driver');
      setUsers(filteredUsers); // Ensure users is always an array
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await axios.delete(`${apipath}/users/details/${userIdToDelete}`);
      await fetchUsers(); // Refresh users after deletion
      setShow(false);
    } catch (error) {
      console.error('Error deleting user:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleStatus = async (driverId, field, currentValue) => {
    try {
      setIsLoading(true);
      const updateData = { [field]: !currentValue };
      console.log('Updating with data:', updateData);

      const response = await axios.put(`${apipath}/users/details/${driverId}`, updateData);
      console.log('API Response:', response);

      if (response.status === 200) {
        setUsers(prevUsers => 
          prevUsers.map(user =>
            user._id === driverId ? { ...user, [field]: !currentValue } : user
          )
        );

        iziToast.success({
          message: "Driver status update successful",
          position: "topCenter",
        });
      } else {
        iziToast.error({
          message: "Driver status update failed",
          position: "topCenter",
        });
      }
    } catch (error) {
      console.error(`Error updating ${field} status:`, error.response || error.message);
      iziToast.error({
        message: "An error occurred",
        position: "topCenter",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleShow = (id) => {
    setUserIdToDelete(id);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  return (
    <>
      <SideBar />
      <Navbar />

      <section className='home'>
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className='bi bi-menu'></i>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete driver</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this driver?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleDelete} disabled={isLoading}>
              {isLoading ? 'Deleting...' : 'Delete'}
            </button>
          </Modal.Footer>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <h5>Driver Details</h5>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Driver Profile</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Joined On</th>
                      <th>Login Status</th>
                      <th>Status</th>
                      <th>Doc. Verify</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(users) && users.map((user, index) => (
                      <tr key={user._id}>
                        <td className='text-center'>{index + 1}</td>
                        <td className='userImage'>
                          <span><img src="imgs/user.png" alt="User" /></span>
                        </td>
                        <td>{user.firstName}{" "}{user.lastName}</td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                        <td>{moment(user.createdAt).format('YYYY-MM-DD')}</td>
                        <td>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={user.status}
                              onChange={() => handleToggleStatus(user._id, 'status', user.status)}
                            />
                            <span className="slider round"></span>
                          </label>
                        </td>
                        <td className={user.status ? "success" : "pending"}>
                          <span><i className={user.status ? "fa-solid fa-check" : "fa-solid fa-spinner"}></i> {user.status ? "Active" : "Inactive"}</span>
                        </td>
                        <td> 
                          <Link to={`/driver-details-view/${user._id}`}>
                            <i className="fa-regular fa-pen-to-square"></i>
                          </Link>
                        </td>
                        <td>
                          <i className="fa-solid fa-trash" onClick={() => handleShow(user._id)} style={{ cursor: 'pointer' }}></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DriveDetails;
