import iziToast from "izitoast";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const SideBar = () => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const toggleSubmenu = (index) => {
    setActiveSubmenu(activeSubmenu === index ? null : index);
  };

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const location = useLocation();
 
  const adminToken = localStorage.getItem('admintoken');
  const adminname = localStorage.getItem('adminname');
  const adminemail = localStorage.getItem('adminemail');
  const admintoken = localStorage.getItem('admintoken');
  if(admintoken === null || admintoken === ""){
    navigate("/");
  }

  const handleLogout = () => {
    localStorage.removeItem('admintoken');
    localStorage.removeItem('adminname');
    localStorage.removeItem('adminemail');
    localStorage.removeItem('adminId');
    // adminLogout();
    // logout();
    
    iziToast.success({
      message: "Logout successful",
      position: "topCenter",
    });
    // navigate("/");
    window.location.href = "/";
  };

  const isTabActive = (path) => {
    return location.pathname === path ? 'activeTab' : '';
  };
  
  return (
    <>
      <div className={`sidebar ${isActive ? "close" : ""}`}>
        <Link className="logo-box text-decoration-none" onClick={toggleSidebar}>
          {/* <i className="bi bi-menu-down"></i> */}
          <img className="mt-4 mx-5" src={`${process.env.PUBLIC_URL}/imgs/logo.jpeg`} width={140} height={25} alt="" />
        </Link>

        <ul className="sidebar-list">
          <li>
            <div className="title">
              <Link to="/adminDashboard" className={`link ${isTabActive('/adminDashboard')}`}>
                <i className="bi bi-grid-3x3"></i>
                <span className="name">Dashboard</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/adminDashboard" className="link submenu-title">
                Dashboard
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/usersedit" className={`link ${isTabActive('/usersedit')}`}>
              <i class="bi bi-people"></i>
                <span className="name">Users Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Users Management</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/driverDetails" className={`link ${isTabActive('/driverDetails')}`}>
                <i className="bi bi-person"></i>
                <span className="name">Driver Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/driverDetails" className="link submenu-title">Driver Management</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/user-ride-booking" className={`link ${isTabActive('/user-ride-booking')}`}>
                <i className="bi bi-person"></i>
                <span className="name">User Ride Booking</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/user-ride-booking" className="link submenu-title">User Ride Booking</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/driver-ride-accept" className={`link ${isTabActive('/driver-ride-accept')}`}>
                <i className="bi bi-person"></i>
                <span className="name">Driver Ride Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/driver-ride-accept" className="link submenu-title">Driver Ride Management</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/faqedit" className={`link ${isTabActive('/faqedit')}`}>
              <i class="bi bi-cash"></i>
                <span className="name">Billing Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Billing Management</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/footeredit" className={`link ${isTabActive('/footeredit')}`}>
                <i className="bi bi-gear"></i>
                <span className="name">Payout Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link  className="link submenu-title">Payout Management</Link>
            </div>
          </li>


          <hr/><center><h5>CMS Section</h5></center><hr/>

          <li>
            <div className="title">
              <Link to="/user-safety-security" className={`link ${isTabActive('/user-safety-security')}`}>
                <i className="bi bi-gear"></i>
                <span className="name">User Safety & Security</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/user-safety-security" className="link submenu-title">User Safety & Security</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/driver-safety-security" className={`link ${isTabActive('/driver-safety-security')}`}>
                <i className="bi bi-gear"></i>
                <span className="name">Driver Safety & Security</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/driver-safety-security" className="link submenu-title">Driver Safety & Security</Link>
            </div>
          </li>
          
          <li>
            <div className="title">
              <Link to="/user-help-support" className={`link ${isTabActive('/user-help-support')}`}>
                <i className="bi bi-gear"></i>
                <span className="name">User Help & Support</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/user-help-support" className="link submenu-title">User Help & Support</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/driver-help-support" className={`link ${isTabActive('/driver-help-support')}`}>
                <i className="bi bi-gear"></i>
                <span className="name">Driver Help & Support</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/driver-help-support" className="link submenu-title">Driver Help & Support</Link>
            </div>
          </li>

          <li className="logout">
            <div className="title">
              <Link className="link">
                <i className="bi bi-box-arrow-left"></i>
                <span className="name" onClick={handleLogout}>Logout</span>
              </Link>
            </div>
            
          </li>
        </ul>
      </div>
    </>
  );
};

export default SideBar;
