import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
  
  const adminId = localStorage.getItem('adminId');

  return (
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                </ul>
                <form class="d-flex align-items-center nav-admin">
                    <img src={`${process.env.PUBLIC_URL}/imgs/user-icon.png`} alt="" class="me-2" />
                    <Link  to={`/profile/${adminId}`} class="text-decoration-none"><span>Admin</span></Link>
                </form>
            </div>
        </div>
    </nav>
  )
}

export default Navbar