import React, { useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import iziToast from "izitoast";

const DriverHelpSupportAdd = () => {
    const navigate = useNavigate();
    const { apipath } = useAuth();

    const [formData, setFormData] = useState({
        helpSupportHeading: '',
        helpSupportText: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        const response = await fetch(apipath + '/help-support-driver/add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        if (response.status === 201) {
            iziToast.success({
                message: "Driver help & support add successful",
                position: "topCenter"
            });
            navigate("/driver-help-support");
        } else {
            iziToast.success({
                message: "Driver help & support add failed",
                position: "topCenter"
            });
        }
    };

    return (
        <>
            <SideBar />
            <Navbar />

            <section className="home">
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className="bi bi-menu"></i>
                </div>

                <div className="container pt-3">
                    <div className="row top-barcolumn">
                        <h5>Add driver help & support details</h5>
                        <div className="row nav-column">
                            <div>
                                <form className="row" onSubmit={handleRegister}>
                                    <div className="col-lg-12 mb-3">
                                        <label for="nav-items1" className="form-label">
                                        Driver Help & Support Heading
                                        </label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="nav-item1"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter Driver Help & Support Heading"
                                            name="helpSupportHeading"
                                            value={formData.helpSupportHeading}
                                            onChange={handleChange}
                                            maxLength={50}
                                            required
                                        >
                                        </textarea>
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <label for="nav-items1" className="form-label">
                                        Driver Help & Support Text
                                        </label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="nav-item1"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter Driver Help & Support Text"
                                            name="helpSupportText"
                                            value={formData.helpSupportText}
                                            onChange={handleChange}
                                            maxLength={1000}
                                            required
                                        >
                                        </textarea>
                                    </div>
                                    <div className="">
                                        <button type="submit" className="btn btn-success">
                                            Update
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};


export default DriverHelpSupportAdd;
