import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useAuth } from "../AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import axios from "axios";

const UserHelpSupportEdit = () => {
    const navigate = useNavigate();
    const { apipath } = useAuth();
    const { helpSupportId } = useParams();

    const [formData, setFormData] = useState({
        helpSupportHeading: '',
        helpSupportText: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        fetchHelpSupportsEdit();
      }, []);
    
    const fetchHelpSupportsEdit = async () => {
    
        try {
          const response = await axios.get(
            apipath + `/help-support/details/${helpSupportId}`
          );
          setFormData(response.data.helpSupport);
        } catch (error) {
          console.error("Error fetching user help & support Details:", error);
        }
      };

    const handleUpdate = async (e) => {
        e.preventDefault();

        const response = await fetch(apipath + `/help-support/details/${helpSupportId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        if (response.status === 200) {
            iziToast.success({
                message: "User help & support update successful",
                position: "topCenter"
            });
            navigate("/user-help-support");
        } else {
            iziToast.success({
                message: "User help & support update failed",
                position: "topCenter"
            });
        }
    };

    return (
        <>
            <SideBar />
            <Navbar />

            <section className="home">
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className="bi bi-menu"></i>
                </div>

                <div className="container pt-3">
                    <div className="row top-barcolumn">
                        <h5>Edit user help & support details</h5>
                        <div className="row nav-column">
                            <div>
                                <form className="row" onSubmit={handleUpdate}>
                                    <div className="col-lg-12 mb-3">
                                        <label for="nav-items1" className="form-label">
                                        User Help & Support Heading
                                        </label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="nav-item1"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter User Help & Support Heading"
                                            name="helpSupportHeading"
                                            value={formData.helpSupportHeading}
                                            onChange={handleChange}
                                            maxLength={50}
                                            required
                                        >
                                        </textarea>
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <label for="nav-items1" className="form-label">
                                        User Help & Support Text
                                        </label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="nav-item1"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter User Help & Support Text"
                                            name="helpSupportText"
                                            value={formData.helpSupportText}
                                            onChange={handleChange}
                                            maxLength={1000}
                                            required
                                        >
                                        </textarea>
                                    </div>
                                    <div className="">
                                        <button type="submit" className="btn btn-success">
                                            Update
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default UserHelpSupportEdit;
