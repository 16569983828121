import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import Navbar from './Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

const UserSafetySecurityDetails = () => {

const [show, setShow] = useState(false);
const [userSafetySecurityIdToDelete, setUserSafetySecurityIdToDelete] = useState(null);

const handleShow = (id) => {
    setUserSafetySecurityIdToDelete(id);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  useEffect(() => {
    $(dataTableRef.current).DataTable();
  }, []);

  const navigate = useNavigate();
    const [safetySecuritys, setSafetySecuritys] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { apipath } = useAuth();

    const dataTableRef = useRef();

    useEffect(() => {
        fetchSafetySecuritys();
    }, []);

    useEffect(() => {
        $(dataTableRef.current).DataTable();
      },[]);

    const fetchSafetySecuritys = async () => {
        try {
            const response = await axios.get(apipath + '/safety-security/details');
            setSafetySecuritys(response.data.safetySecurity);
        } catch (error) {
            console.error('Error fetching safetySecuritys:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            setIsLoading(true);
            await axios
            .delete(apipath + `/safety-security/details/${userSafetySecurityIdToDelete}`);
            await fetchSafetySecuritys(); // Refresh courses after deletion
            setShow(false);
            setIsLoading(false);
        } catch (error) {
            console.error('Error deleting safetySecuritys:', error);
            setIsLoading(false);
        }
    };

  return (
    <>
    <SideBar />
    <Navbar />

    <section className='home'>
    <div className="toggle-sidebar" style={{display: "none"}}>
            <i className='bi bi-menu'></i>
        </div>
        
        <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete user safety & security</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-content1">
                            <p>Are you sure you want to delete this user safety & security?</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="modal-footer-btn" onClick={handleDelete} disabled={isLoading}>
                            {isLoading ? "Deleting..." : "Delete"}
                        </button>
                        <button className="modal-footer-btn" onClick={handleClose}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>


        <div className="container">
            <div className="row foot-tablerow">
                <h5>User Safety & Security Details</h5>
                <div className="add-button">
                            <Link to="/add-user-safety-security"><button>+ Add User Safety & Security</button></Link>
                        </div>
                <div className="col-lg-11 maintable-column">
                    <div className="container mt-4">
                        <table id="datatable" className="table" ref={dataTableRef}>
                            <thead>
                                <tr> 
                                    <th>SN</th>
                                    <th>User Safety & Security Heading</th>
                                    <th>User Safety & Security Text</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                            {safetySecuritys.map((safetySecurity, index) => (
                      <tr key={safetySecurity._id}>
                        <td className='text-center'>{index + 1}</td>
                        <td>{safetySecurity.safetySecurityHeading}</td>
                        <td>{safetySecurity.SafetySecurityText}</td>
                        <td>
                        <Link to={`/edit-user-safety-security/${safetySecurity._id}`}><i className="fa-regular fa-pen-to-square"></i></Link> &nbsp;
                          <i className="fa-solid fa-trash" onClick={() => handleShow(safetySecurity._id)} style={{ cursor: 'pointer' }}></i>
                        </td>
                      </tr>
                    ))}  

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
        </section>
    
    </>
  )
}

export default UserSafetySecurityDetails;